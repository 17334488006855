import 'jquery';
import Shuffle from 'shufflejs';

jQuery(document).ready( function() {
    jQuery('.dataGridList').each( function() {
        let shuffleConf = {
            columnWidth: 400,
            itemSelector: '.item',
            sizer: jQuery(this).siblings('.my-sizer-element')
        };

        let shuffle = new Shuffle(jQuery(this)[0], shuffleConf);
    });
});