import 'jquery';
import 'mmenu-js';
import { BREAKPOINTS } from "../helpers/variables";

document.addEventListener(
    "DOMContentLoaded", () => {
        const menuOpts = {};
        const menuConfig = {
            offCanvas: {
                clone: true
            }
        };
        const menu = new Mmenu("#main-navigation", menuOpts, menuConfig);
        const api = menu.API;

        api.bind( "close:start", function() {
            jQuery("#mobile-menu-button").removeClass("is-active");
        });

        document.querySelector( "#mobile-menu-button" )
            .addEventListener(
                "click", ( evnt ) => {
                    evnt.preventDefault();
                    api.open();
                    jQuery('#mobile-menu-button').toggleClass('-active');
                }
            );

        /*
        document.querySelector( ".mobileNavClose" )
            .addEventListener(
                "click", ( evnt ) => {
                    evnt.preventDefault();
                    api.close();
                    jQuery('.mobileNavToggle').toggleClass('-active');
                }
            );
         */
});
