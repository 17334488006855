import 'jquery';
import 'slick-carousel';
import { getArrowIcon, SLICK_CONF } from '../slick-common/slick-common';
import { BREAKPOINTS } from "../helpers/variables";

jQuery(document).ready( function() {
    jQuery('.partnersList.-isCarousel').each( function() {
        let slickConf = {
            ...SLICK_CONF,
            arrows: false,
            slidesToShow: 5,
            responsive: [
                {
                    breakpoint: BREAKPOINTS.md,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        };

        jQuery(this).slick(slickConf);
    });
});