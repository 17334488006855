import 'jquery';
import 'slick-carousel';
import { getArrowIcon, SLICK_CONF } from '../slick-common/slick-common';
import { BREAKPOINTS } from "../helpers/variables";

jQuery(document).ready( function() {
    // Slider always on mobile, not always on desktop/tablet
    jQuery('.content-listings-carousel').each( function() {
        let slickConf = {
            ...SLICK_CONF,
            arrows:true,
            dots: false,
            appendArrows: jQuery(this).parents().eq(1),
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            controls: true,
            autoplay:true,
            autoplaySpeed: 4000,
            responsive: [
                {
                    breakpoint: BREAKPOINTS.lg,
                    settings: {
                        slidesToShow: 2,
                        arrows:true,

                    }
                },
                {
                    breakpoint: BREAKPOINTS.md,
                    settings: {
                        slidesToShow: 1,
                        arrows:true,
                    }
                }
            ]
        };

        jQuery(this).slick(slickConf);
    });
});