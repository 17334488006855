const BREAKPOINT_XS = 0;
const BREAKPOINT_SM = 576;
const BREAKPOINT_MD = 768;
const BREAKPOINT_LG = 992;
const BREAKPOINT_XL = 1200;
const BREAKPOINT_XXL = 1400;

const BREAKPOINTS = {
    xs: BREAKPOINT_XS,
    sm: BREAKPOINT_SM,
    md: BREAKPOINT_MD,
    lg: BREAKPOINT_LG,
    xl: BREAKPOINT_XL,
    xxl: BREAKPOINT_XXL
};

export { BREAKPOINTS };