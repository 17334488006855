// This file contains all parachute imports, e.g:
// import './parachute/foo/foo.js';
// import './parachute/bar/bar.js';

import './accordion/accordion';
import './banners/banners';
import './content-listings/content-listings';
import './nav/nav';
import './tabs/tabs';
import './reviews/reviews';
import './partners/partners';
import './icon-tabs/icon-tabs';
import './icon-grid/icon-grid';
import './icon-links/icon-links';
import './tiers/tiers';
import './data-grid/data-grid';
import './mobile-nav/mobile-nav';