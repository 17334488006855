import 'jquery';
import 'slick-carousel';
import { getArrowIcon, SLICK_CONF } from '../slick-common/slick-common';

jQuery(document).ready( function() {
    jQuery('.reviews-container.-is-carousel .reviews').each( function() {
        let slickConf = {
            ...SLICK_CONF,
            arrows:false,
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            controls: true,
            autoplay:true,
            autoplaySpeed: 7000,
        };
        jQuery(this).slick(slickConf);
    });
});