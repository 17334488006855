import 'jquery';

jQuery(document).ready( function() {
    var stickyNavTop = jQuery('.main-nav').offset().top;
    var headerHeight = jQuery("#header").height();
    var stickyNav = function(){
        jQuery("#header").css("height", headerHeight);
        var scrollTop = jQuery(window).scrollTop(); // our current vertical position from
        if (scrollTop > stickyNavTop) {
            jQuery('.main-nav').addClass('sticky');
        } else {
            jQuery('.main-nav').removeClass('sticky');
        }
    };
    stickyNav();

    jQuery(window).scroll(function() {
        stickyNav();
    });
});