import 'jquery';
import 'slick-carousel';
import { SLICK_CONF } from '../slick-common/slick-common';

const handleControlClick = e => {
    e.preventDefault();

    let el = jQuery(e.currentTarget);
    let componentId = jQuery(e.currentTarget).attr('data-attr-component-id');
    let id = jQuery(e.currentTarget).attr('data-attr-id');
    let contentEl = jQuery(`#tabContent-${id}`);

    if(contentEl.length > 0) {
        jQuery(`#tabControlsList-${componentId} .-active`).removeClass('-active');
        jQuery(`#tabContentList-${componentId} .-active`).removeClass('-active');

        jQuery(el).parent().addClass('-active');
        jQuery(el).addClass('-active');
        contentEl.addClass('-active');
    }
};

jQuery(document).ready( function() {
    jQuery('.tabControlsList .item:first-child').addClass('-active');
    jQuery('.tabContentList .item:first-child').addClass('-active');

    jQuery('.tabControlsList .button').click(handleControlClick);

    jQuery('.tabControlsList.-isCarousel').each( function() {
        let slickConf = {
            ...SLICK_CONF,
            arrows: false,
            slidesToShow: 3
        };

        jQuery(this).slick(slickConf);
    });
});