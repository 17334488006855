import 'jquery';
import 'slick-carousel';
import { SLICK_CONF } from '../slick-common/slick-common';

jQuery(document).ready( function() {
    jQuery('.iconGridList.-isCarousel').each( function() {
        let slickConf = {
            ...SLICK_CONF,
            arrows: false,
            slidesToShow: 3
        };

        jQuery(this).slick(slickConf);
    });
});